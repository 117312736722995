import React from "react";
import './Projects.css';  // Add this line to import the CSS
import Water4 from '../assets/Water4.png';

function Projects() {
    return (
        <div id="projects" className='projects-container'>
            <div className='image-container'>
                <img src={Water4} alt="project image" className='side-image' />
            </div>
            <div className='project-details'>
                <h1>Borehole Rehabilitation</h1>
                <p>
                    The borehole rehabilitation program operates in lower Belt kween, Sironko, Bukedea, Tororo, and Mbale Districts
                    currently, and we have completed 20 rehabilitations since 2022. During each rehabilitation, we engage the community
                    through our WASH program and set up a lasting structure of sustainability. We do backyard farming to boost nutrition
                    in family households and for extra income. We incorporate a VSLA (Village Savings Loan Association) program to help
                    encourage the water users of that borehole. The VSLA program helps in conjunction with the water user committee
                    created to oversee operation and maintenance of the borehole by creating accountability and transparency. The water
                    user committee can collect money from the water users through the savings groups so when the borehole breaks down
                    there is already money set aside to cover costs. We regularly monitor all of our programs on a monthly basis. Our Field
                    Team, upon the start of a new project, carries out an assessment of the borehole and then conducts a pump test of the
                    borehole to find the dynamic water level and the yield of the borehole. This information allows us to make decisions on
                    supplies and whether we continue with the program. Upon continuing, we hold a community meeting to come to an
                    agreement with the community on the way forward. If the meeting was successful, we conduct our WASH training.
                    Afterward, we do a casting of the platform then once cured we carry out installation of the pump then hand over back
                    to the community. Once the initial work has been completed, we conduct routine maintenance of boreholes in our
                    program.
                </p>
            </div>
        </div>
    );
}

export default Projects;
