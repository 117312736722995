import styled from "styled-components";
import water7 from "../assets/Water7.png"; // Add path to your water background image

export const Box = styled.div`
    padding: 5% 2.5%;
    background: #f5f5f5;
    width: 100%;
    position: relative;
    overflow: hidden;

    @media (max-width: 1000px) {
        padding: 70px 30px;
    }

    @media (max-width: 768px) {
        padding: 50px 20px;
    }

    @media (max-width: 480px) {
        padding: 30px 10px;
    }
`;

export const BackgroundWater = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${water7}) no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    z-index: -1;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    z-index: 1;

    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`;

export const Column = styled.div`
    flex: 1;
    margin: 10px;

    @media (max-width: 768px) {
        margin: 5px 0;
        text-align: center;
    }
`;

export const FooterLink = styled.a`
    color: #333;
    margin: 0 10px;
    font-size: 20px;
    text-decoration: none;

    &:hover {
        color: green;
        transition: 200ms ease-in;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        margin: 0 5px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
        margin: 0 3px;
    }
`;

export const Heading = styled.p`
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;

    a {
        margin: 0 10px;
    }

    i {
        font-size: 20px;
        color: #333;

        &:hover {
            color: green;
            transition: 200ms ease-in;
        }

        @media (max-width: 768px) {
            font-size: 18px;
        }

        @media (max-width: 480px) {
            font-size: 16px;
        }
    }
`;

export const Address = styled.p`
    font-size: 14px;
    line-height: 1.6;
    color: #333;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 480px) {
        font-size: 11px;
    }
`;

export const LegalText = styled.p`
    font-size: 15px;
    line-height: 1.8;
    color: #333;

    @media (max-width: 768px) {
        font-size: 13px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

export const FooterBottom = styled.div`
    border-top: 1px solid #ddd;
    padding: 10px 20px;
    background-color: #30a5e9;
    text-align: center;

    @media (max-width: 768px) {
        padding: 10px;
    }

    @media (max-width: 480px) {
        padding: 5px;
    }
`;


export const FooterLinks = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
    }
`;

export const FooterLinkItem = styled.a`
    color: #333;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
        margin: 5px 0;
    }
`;

export const Logo = styled.img`
    max-width: 190px;
    height: auto;

    @media (max-width: 768px) {
        max-width: 150px;
    }

    @media (max-width: 480px) {
        max-width: 120px;
    }
`;

export const Copyright = styled.div`
    text-align: center;
    color: white;
    margin-top: 10px;
    font-size: 14px;

    @media (max-width: 768px) {
        font-size: 12px;
    }

    @media (max-width: 480px) {
        font-size: 10px;
    }
`;
