import React from 'react';
import './TermsAndConditions.css';

function TermsAndConditions() {
    return (
        <div className="terms-container">
            <h1>Terms & Conditions</h1>
            <p>Welcome to the Aquifer Foundation. These terms and conditions outline the rules and regulations for the use of our website.</p>
            <h2>Introduction</h2>
            <p>By accessing this website, you accept these terms and conditions in full. Do not continue to use the Aquifer Foundation website if you do not accept all of the terms and conditions stated on this page.</p>
            <h2>Intellectual Property Rights</h2>
            <p>Unless otherwise stated, Aquifer Foundation and/or its licensors own the intellectual property rights for all material on Aquifer Foundation. All intellectual property rights are reserved.</p>
            <h2>Restrictions</h2>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
                <li>Publishing any website material in any other media;</li>
                <li>Selling, sublicensing, and/or otherwise commercializing any website material;</li>
                <li>Using this website in any way that is or may be damaging to this website;</li>
                <li>Using this website in any way that impacts user access to this website;</li>
                <li>Engaging in any data mining, data harvesting, data extracting, or any other similar activity concerning this website;</li>
                <li>Using this website to engage in any advertising or marketing.</li>
            </ul>
            <h2>Limitation of Liability</h2>
            <p>In no event shall Aquifer Foundation, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.</p>
            <h2>Indemnification</h2>
            <p>You hereby indemnify to the fullest extent Aquifer Foundation from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.</p>
            <h2>Severability</h2>
            <p>If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
            <h2>Variation of Terms</h2>
            <p>Aquifer Foundation is permitted to revise these terms at any time as it sees fit, and by using this website, you are expected to review these terms regularly.</p>
            <h2>Assignment</h2>
            <p>The Aquifer Foundation is allowed to assign, transfer, and subcontract its rights and/or obligations under these terms without any notification.</p>
            <h2>Entire Agreement</h2>
            <p>These terms constitute the entire agreement between Aquifer Foundation and you concerning your use of this website and supersede all prior agreements and understandings.</p>
            <h2>Governing Law & Jurisdiction</h2>
            <p>These terms will be governed by and interpreted per the laws of the State of Uganda, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Uganda for resolving any disputes.</p>
        </div>
    );
}

export default TermsAndConditions;
