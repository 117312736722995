import React, { useState } from 'react';
import './Contact.css';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('process.env.REACT_APP_API_URL', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setSubmitted(true);
            } else {
                const errorText = await response.text(); // Get error message from response body
                setError(`Error submitting the form: ${errorText}`);
            }
        } catch (error) {
            setError(`Error submitting the form: ${error.message}`);
        }
    };

    return (
        <div className="contact-us-container">
            <h1>Contact Us</h1>
            {submitted ? (
                <div className="thank-you-message">
                    <h2>Thank you for contacting Aquifer Foundation!</h2>
                    <p>We will get back to you as soon as possible.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    );
}

export default Contact;
