import React from 'react';
import './Home.css';
import Water2 from '../assets/Water2.jpeg';
import water1 from '../assets/water1.png';
import Water8 from '../assets/Water8.png';
import garden from '../assets/garden.jpg';


function Home() {
    return (
        <div className='home'>
            <div className='container'>
                <div className="hero">

                    <img src={Water2} alt="Aquifer Foundation Project" className="full-width-image" />
                    <div className="overlay-text">
                        <div className='overlay-text1'>Clean water for All: </div>Aquifer Foundation
                    </div>
                </div>
                <div className="about">
                    <h1>Who we are</h1>
                    <p>
                        Aquifer Foundation is a Christian-based organization located in Mbale,
                        Uganda, with a sole focus on clean water projects and sustainability.
                        Established in 2022, we work alongside the local government,
                        primarily in the Eastern Region. < br />
                        The organization at this time primarily focuses on borehole rehabilitation, improving households and WASH programs.
                    </p>
                </div>

                <div id="Mission" className='Mission'>
                    <div className='image-container'>
                        <img src={water1} alt="mission image" className='side-image' />
                    </div>
                    <div className='mission'>
                        <h1>Our Mission</h1>
                        <p>
                            The Aquifer Foundation is committed to ensuring <strong>clean water</strong> for vulnerable communities in Uganda and empowering women through education and support to engage in backyard farming, promoting nutrition and generating supplementary income. Through sustainable solutions and community engagement, we aim to enhance quality of life, foster self-reliance, and mitigate the impacts of water scarcity.
                        </p>
                    </div>
                </div>
                <img src={garden} alt="green paper" className='side-image' />
                <div id="vision" className='Mission1'>
                    <div className='mission'>
                        <h1>Our Vision</h1>
                        <p>
                            We visualize a Uganda where every community has reliable access to clean water, and where women are empowered as key agents of change through backyard farming initiatives. We envision thriving, resilient communities where nutritious food is abundant, incomes are diversified, and health and well-being are prioritized.
                        </p>
                    </div>
                    <div className='image-container'>
                        <img src={Water8} alt="vision image" className='side-image' />
                    </div>
                </div>
                {/* <div className="objectives">
                    <h1>Our Objectives</h1>
                    <ul>
                        <li><strong>Expand Access:</strong> Increase the number of communities with access to clean water by a certain percentage within a specified timeframe.</li>
                        <li><strong>Sustainability:</strong> Implement sustainable water management practices to ensure long-term availability and quality of water resources.</li>
                        <li><strong>Education and Awareness:</strong> Develop educational programs to raise awareness about the importance of clean water and promote water conservation practices within communities.</li>
                        <li><strong>Infrastructure Development:</strong> Invest in infrastructure projects to improve water distribution systems and ensure reliable access to clean water.</li>
                        <li><strong>Community Engagement:</strong> Foster partnerships and engage with local communities to understand their needs and involve them in decision-making processes.</li>
                        <li><strong>Monitoring and Evaluation:</strong> Establish robust monitoring and evaluation systems to track progress, identify challenges, and adjust strategies accordingly.</li>
                        <li><strong>Research and Innovation:</strong> Invest in research and innovation to develop new technologies and solutions for water treatment and conservation.</li>
                        <li><strong>Emergency Response:</strong> Develop contingency plans and emergency response protocols to address water-related crises and ensure swift assistance to affected communities.</li>
                        <li><strong>Policy Advocacy:</strong> Advocate for policies that prioritize clean water access and conservation at local, national, and international levels.</li>
                        <li><strong>Partnerships:</strong> Collaborate with governments, NGOs, businesses, and other stakeholders to leverage resources and expertise for greater impact.</li>
                        <li><strong>Integrated Solutions:</strong> Implement integrated water and agriculture projects to provide clean water for households and cultivate nutritious vegetables for community consumption.</li>
                        <li><strong>Community Gardens:</strong> Establish community gardens and agricultural training programs to empower households to grow their own nutritious vegetables using sustainable water sources.</li>
                        <li><strong>Nutritional Education:</strong> Develop educational initiatives to promote the importance of a balanced diet and provide training on vegetable gardening techniques to improve household nutrition.</li>
                        <li><strong>Water-Efficient Agriculture:</strong> Promote water-efficient agricultural practices such as drip irrigation and rainwater harvesting to maximize water usage for both vegetable cultivation and household consumption.</li>
                        <li><strong>Food Security:</strong> Enhance food security in communities by diversifying local diets with a steady supply of fresh, nutritious vegetables grown locally.</li>
                        <li><strong>Capacity Building:</strong> Build the capacity of local communities to manage water resources effectively and sustainably, ensuring the long-term success of water and agriculture initiatives.</li>
                        <li><strong>Market Access:</strong> Facilitate access to markets for surplus vegetable produce, creating opportunities for income generation and economic empowerment within communities.</li>
                        <li><strong>Monitoring and Evaluation:</strong> Establish monitoring and evaluation mechanisms to assess the impact of water and agriculture interventions on household nutrition, income levels, and overall well-being.</li>
                        <li><strong>Innovation and Technology:</strong> Explore innovative technologies such as hydroponics or vertical farming to maximize vegetable production while minimizing water usage and land requirements.</li>
                        <li><strong>Policy Advocacy:</strong> Advocate for policies that support integrated water and agriculture projects, including funding mechanisms, regulatory frameworks, and incentives for sustainable practices.</li>
                    </ul>
                </div>*/}
            </div>
        </div>
    );
}

export default Home;
