import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";
import logo5 from "../logo5.png";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <nav className="nav container">
                <NavLink to="/" className="nav__logo">
                    <img src={logo5} alt="Aquifer Foundation Logo" />
                </NavLink>

                <div className="nav__actions">
                    <NavLink to="/donate" className="nav__link nav__cta">
                        Donate
                    </NavLink>
                    <div className="nav__toggle" onClick={toggleMenu}>
                        <IoMenu />
                    </div>
                </div>

                <div className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`}>
                    <ul className="nav__list">
                        <li className="nav__item">
                            <NavLink to="/" className="nav__link" onClick={toggleMenu}>
                                Home
                            </NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink to="/Projects" className="nav__link" onClick={toggleMenu}>
                                Projects
                            </NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink to="/OurStory" className="nav__link" onClick={toggleMenu}>
                                Our Story
                            </NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink to="/Blog" className="nav__link" onClick={toggleMenu}>
                                Blog
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav__close" onClick={toggleMenu}>
                        <IoClose />
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
