import React from 'react';

function About() {
    return (
        <div className='container'>
            <section className="about">
                <h2>About Us</h2>
                <p>
                    Aquifer Foundation is a Christian-based organization located in Mbale,
                    Uganda. We focus on clean water projects and sustainability, established
                    in 2022. We work alongside the local government, primarily in the Eastern
                    Region.
                </p>
                <p>
                    Our mission is to provide clean water access to communities in need,
                    improving public health and promoting sustainable water management practices.
                </p>
            </section>
        </div>
    );
}

export default About;
