import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CardPayment.css';

function CardPayment() {
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        cardHolderName: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { formData } = location.state;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetails({
            ...cardDetails,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Perform basic validation
        if (!cardDetails.cardNumber || !cardDetails.expiryDate || !cardDetails.cvv || !cardDetails.cardHolderName) {
            setErrorMessage('All fields are required.');
            return;
        }

        // Mock payment processing (replace with real payment gateway integration)
        try {
            const response = await mockPaymentProcessing(cardDetails, formData);
            if (response.success) {
                navigate('/thank-you', { state: { formData, cardDetails } });
            } else {
                setErrorMessage('Payment failed. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    const mockPaymentProcessing = async (cardDetails, formData) => {
        // Simulate a delay for payment processing
        return new Promise((resolve) => {
            setTimeout(() => {
                // Simulate a successful payment response
                resolve({ success: true });
            }, 2000);
        });
    };

    return (
        <div className="card-payment-container">
            <h1>Card Payment</h1>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <label htmlFor="cardHolderName">Card Holder Name:</label>
                <input
                    type="text"
                    id="cardHolderName"
                    name="cardHolderName"
                    value={cardDetails.cardHolderName}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="cardNumber">Card Number:</label>
                <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={cardDetails.cardNumber}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="expiryDate">Expiry Date:</label>
                <input
                    type="text"
                    id="expiryDate"
                    name="expiryDate"
                    value={cardDetails.expiryDate}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="cvv">CVV:</label>
                <input
                    type="text"
                    id="cvv"
                    name="cvv"
                    value={cardDetails.cvv}
                    onChange={handleChange}
                    required
                />

                <button type="submit">Submit Payment</button>
            </form>
        </div>
    );
}

export default CardPayment;
