import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './PaymentOptions.css';

function PaymentOptions() {
    const navigate = useNavigate();
    const location = useLocation();
    const { formData } = location.state;

    const handlePaymentOption = (option) => {
        navigate('/payment-details', { state: { formData, option } });
    };

    return (
        <div className="payment-options-container">
            <h1>Choose Payment Method</h1>
            <div className="payment-buttons">
                <button onClick={() => handlePaymentOption('card')}>Donate via Card Payment</button>
                <button onClick={() => handlePaymentOption('mobile')}>Donate via Mobile Payment</button>
            </div>
        </div>
    );
}

export default PaymentOptions;
