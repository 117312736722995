import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>Welcome to the Aquifer Foundation. This privacy policy outlines how we collect, use, and protect your personal information when you use our website.</p>

            <h2>Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li><strong>Personal Identification Information:</strong> Email address and name etc.</li>
                <li><strong>Non-Personal Identification Information:</strong> Browser type, operating system, and browsing habits.</li>
            </ul>

            <h2>How We Use Collected Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
                <li>To provide and improve our services.</li>
                <li>To personalize user experience.</li>
                <li>To send periodic emails related to updates, promotions, or service information.</li>
            </ul>

            <h2>How We Protect Your Information</h2>
            <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</p>

            <h2>Sharing Your Personal Information</h2>
            <p>We do not sell, trade, or rent users information</p>

            <h2>Changes to Our Privacy Policy</h2>
            <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

            <h2>Contacting Us</h2>
            <p>If you have any questions about this privacy policy, please <a href="/Contact">contact us</a>.</p>

        </div>
    );
}

export default PrivacyPolicy;
