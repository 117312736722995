import React from 'react';
import garden from '../assets/garden.jpg';
import './OurStory.css';

const OurStory = () => {
    return (
        <section className="our-story">
            <div className="container">
                <h2 className="section-title">Our Story</h2>
                <div className="story-content">
                    <p className="story-intro">
                        Aquifer Foundation, established in 2022, is a Christian-based organization dedicated to transforming communities in Mbale, Uganda, through sustainable water solutions and empowerment initiatives.
                    </p>
                    <div className="story-section">
                        <h3>Founding</h3>
                        <p>Founded by with passion to address water scarcity issues in Uganda’s Eastern Region, Aquifer Foundation began its journey to improve water access and promote sustainable living.</p>
                    </div>
                    <div className="story-section">
                        <h3>Mission</h3>
                        <p>Aquifer Foundation's mission is twofold: to ensure clean water access for vulnerable communities and to empower women through education and support in backyard farming. By enhancing nutrition and generating income, we aim to foster self-reliance and resilience.</p>
                    </div>
                    <img src={garden} alt="green paper" className='side-image' />
                    <div className="story-section">
                        <h3>Focus Areas</h3>
                        <ul>
                            <li><strong>Borehole Rehabilitation:</strong> Rehabilitating boreholes to provide reliable and safe water sources for households.</li>
                            <li><strong>WASH Programs:</strong> Implementing Water, Sanitation, and Hygiene (WASH) programs to improve community health and hygiene practices.</li>
                        </ul>
                    </div>
                    <div className="story-section">
                        <h3>Impact</h3>
                        <p>Since our establishment, we have successfully rehabilitated numerous boreholes, benefiting communities and schools in Mbale. Our WASH programs have promoted better hygiene practices, reducing waterborne diseases and improving overall community health.</p>
                    </div>
                    <div className="story-section">
                        <h3>Future Vision</h3>
                        <p>Looking ahead, Aquifer Foundation envisions a Uganda where every community has sustainable access to clean water. We aim to expand our impact by integrating more women into our empowerment programs, transforming them into key agents of change through backyard farming initiatives.</p>
                    </div>
                </div>
                <div className="get-involved">
                    <h3>Get Involved</h3>
                    <p>Join us in making a lasting impact. Whether through donations, volunteering, or spreading awareness, your support helps us bring clean water and sustainable solutions to communities in need.</p>
                </div>
            </div>
        </section>
    );
};

export default OurStory;
