import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Donate.css';

function Donate() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        address: '',
        amount: ''
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form data submission logic here
        navigate('/payment-options', { state: formData });

    };

    return (
        <div className="donate-container">
            <div>s</div>
            <h1>Donate to Aquifer Foundation</h1>
            <p>Your support helps us provide clean water and sustainable solutions to communities in need. Thank you for your generosity!</p>
            <p>Take note, Online donation is not yet supported, donate via bank transfer or mail us a check, we apologize for any inconviniences</p>
            <div className="donate-section">
                <div className="donate-options">
                    <h2>Donate via bank Transfer</h2>
                    <ul>
                        <li>Account Number: 01110015386854</li>
                        <li>Mail a Check: info@aquifer-foundation.org</li>
                        <li>Volunteer: <a href="#">Learn more about volunteering</a></li>
                    </ul>
                </div>
                <div className="donate-form">
                    <h2>Donate Online</h2>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

                        <label htmlFor="address">Address:</label>
                        <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />

                        <label htmlFor="amount">Donation Amount:</label>
                        <input type="number" id="amount" name="amount" value={formData.amount} onChange={handleChange} required />

                        <button type="submit">Donate</button>
                    </form>
                </div>


            </div>

        </div>
    );
}

export default Donate;
