import React from 'react';
import "./CookiePolicy.css";

function CookiePolicy() {
    return (
        <div className="cookie-policy-container">
            <div className='cookie1'>
                <h1>Cookie Policy</h1>
                <p>Our website uses cookies to improve your experience. This Cookie Policy explains what cookies are, how we use them, and how you can manage them.</p>
            </div>
            <div className='cookie2'>
                <h1>What are cookies?</h1>
                <p>Cookies are small text files stored on your device when you visit our website. They help us recognize your device and remember your preferences.</p>
            </div>
            <div className='cookie3'>
                <h1>Types of cookies we use</h1>
                <ul>
                    <li><strong>Essential cookies:</strong> These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas.</li>
                    <li><strong>Performance cookies:</strong> These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.</li>
                    <li><strong>Functional cookies:</strong> These cookies enable the website to provide enhanced functionality and personalization.</li>
                    <li><strong>Advertising cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests.</li>
                </ul>
            </div>
            <div className='cookie4'>
                <h1>Managing cookies</h1>
                <p>You can manage your cookie preferences through your browser settings. Note that disabling cookies may affect the functionality of our website. < br />
                    For more information on how we use cookies, please <a href="/Contact">contact us</a>.</p>

            </div>
        </div>
    );
};

export default CookiePolicy;
