import React from 'react';
import './Blog.css';

const Blog = () => {
    const blogPosts = [
        {
            title: "Transforming Communities: The Impact of Borehole Rehabilitation in Eastern Uganda",
            date: "June 10, 2024",
            excerpt: "Discover how our borehole rehabilitation projects are changing lives in Eastern Uganda...",
            link: "/blog/impact-of-borehole-rehabilitation"
        },
        {
            title: "Meet Our Volunteers: Stories from the Field",
            date: "May 25, 2024",
            excerpt: "Hear from our volunteers about their experiences working with the Aquifer Foundation...",
            link: "/blog/volunteer-stories"
        },
        // Add more blog posts as needed
    ];

    return (
        <div className="blog-container">
            <div>s</div>
            <h1>Our Blog</h1>
            <div className="blog-posts">
                {blogPosts.map((post, index) => (
                    <div key={index} className="blog-post">
                        <h2>{post.title}</h2>
                        <p className="date">{post.date}</p>
                        <p>{post.excerpt}</p>
                        <a href={post.link} className="read-more">Read more</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
