import React from "react";
import { Link } from 'react-scroll';
import logo5 from "../logo5.png";
import Contact from './Contact';
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
    SocialIcons,
    Address,
    LegalText,
    FooterBottom,
    FooterLinks,
    FooterLinkItem,
    Logo,
    Copyright,
    BackgroundWater
} from "./FooterStyles";

const Footer = () => {
    return (
        <Box>
            <BackgroundWater />
            <FooterContainer>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" />
                <Row>
                    <Column>
                        <Logo src={logo5} alt="Aquifer Foundation Logo" />
                        <Address>
                            Aquifer Foundation<br />
                            Plot 128 Wanale Road, Busamaga.<br />
                            Mbale, Uganda
                        </Address>
                    </Column>
                    <Column>
                        <Heading>CONNECT WITH US</Heading>
                        <SocialIcons>
                            <FooterLink href="#"><i className="fab fa-facebook-f"></i></FooterLink>
                            <FooterLink href="#"><i className="fab fa-twitter"></i></FooterLink>
                            <FooterLink href="#"><i className="fab fa-instagram"></i></FooterLink>
                        </SocialIcons>
                    </Column>
                    <Column>
                        <LegalText>
                            Aquifer Foundation is a nonprofit, charitable organization.<br />
                            Clean water for all.
                        </LegalText>
                    </Column>
                </Row>
                <FooterBottom>
                    <FooterLinks>
                        <FooterLinkItem href="/TermsAndConditions">Terms & Conditions</FooterLinkItem>
                        <FooterLinkItem href="/PrivacyPolicy">Privacy Policy</FooterLinkItem>
                        <FooterLinkItem href="/Contact">Contact us</FooterLinkItem>
                        <FooterLinkItem href="/CookiePolicy">Cookies</FooterLinkItem>
                    </FooterLinks>
                    <Copyright>
                        &copy; {new Date().getFullYear()} Aquifer Foundation. All rights reserved.
                    </Copyright>
                </FooterBottom>
            </FooterContainer>
        </Box>
    );
};

export default Footer;
