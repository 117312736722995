import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Donate from './components/Donate';
import Footer from './components/Footer';
import Header from './components/Header';
import Blog from './components/Blog';
import CookiePolicy from './components/CookiePolicy';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import OurStory from './components/OurStory';
import './styles.css';
import PaymentOptions from './components/PaymentOptions';
import CardPayment from './components/CardPayment';

function App() {
  return (
    <Router>
      <div className="App">

        <Navbar />  {/* Render Navbar inside the App div */}


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/OurStory" element={<OurStory />} />
          <Route path="/CookiePolicy" element={<CookiePolicy />} />
          <Route path="/Donate" element={<Donate />} />
          <Route path="/payment-options" element={<PaymentOptions />} />
          <Route path="/payment-details" element={<CardPayment />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Blog" element={<Blog />} />
        </Routes>

        <Footer />
      </div>
    </Router >
  );
}

export default App;

